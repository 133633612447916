/*
 ********** FORUM POSTS **********
 */

// GET FORUM POSTS BY ASSIGNMENT ID
export const getForumPostsByAssignmentId = (builder) => {
  return builder.query({
    query: (body) => ({
      url: `/forumPosts.list`,
      method: 'POST',
      body,
    }),
    transformResponse: (response) => ({
      posts: response.data,
      cursor: response.nextCursor,
    }),
    providesTags: (result) =>
      // is result available?
      result
        ? // successful query
          [
            ...result.posts.map(({ id }) => ({ type: 'Post', id })),
            { type: 'Post', id: 'LIST' },
          ]
        : // an error occurred, but we still want to refetch this query when `{ type: 'Post', id: 'LIST' }` is invalidated
          [{ type: 'Post', id: 'LIST' }],
  })
}

// CREATE FORUM POST
export const createForumPost = (builder) => {
  return builder.mutation({
    query: (forumPost) => ({
      url: '/forumPosts.create',
      method: 'POST',
      body: forumPost,
    }),
    transformResponse: (response) => response.data,
    // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
    // that newly created post could show up in any lists.
    invalidatesTags: [{ type: 'Posts', id: 'LIST' }],
  })
}

// UPDATE FORUM POST
export const updateForumPost = (builder) => {
  return builder.mutation({
    query: ({ postId, ...rest }) => ({
      url: '/forumPosts.update',
      method: 'POST',
      body: { id: postId, ...rest },
    }),
    // Invalidates all queries that subscribe to this Post `id` only.
    invalidatesTags: (result, error, { id }) => [{ type: 'Post', id }],
  })
}

export const deleteForumPost = (builder) => {
  return builder.mutation({
    query: ({ id, submissionId }) => ({
      url: '/forumPosts.delete',
      method: 'POST',
      body: { id, submissionId },
    }),
    // Invalidates all queries that subscribe to this Post `id` only.
    invalidatesTags: (result, error, { id }) => [{ type: 'Post', id }],
  })
}

// TOGGLE FORUM POST LIKE
export const toggleForumPostLike = (builder) => {
  return builder.mutation({
    query: ({ postId, userId }) => ({
      url: '/forumPosts.toggleLike',
      method: 'POST',
      body: { postId, userId },
    }),
  })
}

/*
 ********** FORUM POST COMMENTS **********
 */
// GET FORUM POST COMMENTS
export const getForumPostComments = (builder) => {
  return builder.query({
    query: ({ postId }) => ({
      url: `/forumPosts.listComments`,
      method: 'POST',
      body: { id: postId },
    }),
  })
}

// CREATE FORUM POST COMMENT
export const createForumPostComment = (builder) => {
  return builder.mutation({
    query: ({ postId, comment, userId }) => ({
      url: '/forumPosts.postComment',
      method: 'POST',
      body: { id: postId, comment, userId },
    }),
  })
}

// TOGGLE FORUM POST COMMENT LIKE
export const toggleForumPostCommentLike = (builder) => {
  return builder.mutation({
    query: ({ forumPostCommentId, forumPostId, userId }) => ({
      url: '/forumPostComments.toggleLike',
      method: 'POST',
      body: { forumPostCommentId, forumPostId, userId },
    }),
  })
}

export default {
  // Forum Posts
  getForumPostsByAssignmentId,
  createForumPost,
  updateForumPost,
  deleteForumPost,
  toggleForumPostLike,
  // Forum Post Comments
  getForumPostComments,
  createForumPostComment,
  toggleForumPostCommentLike,
}

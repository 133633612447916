export const getInstructors = (builder) => {
  return builder.query({
    query: (params) => ({
      url: '/users.list',
      method: 'POST',
      body: { ...params, filter: 'instructors' },
    }),
    transformResponse: (response) => response.data,
  })
}

export default {
  getInstructors,
}

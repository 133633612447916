export const getUserRecordings = (builder) => {
  return builder.query({
    query: (userId) => ({
      url: `users.listRecordings`,
      method: 'POST',
      body: {
        userId,
      },
    }),
    transformResponse: (response) => {
      // Ordenar los posts por nombre
      return response.data.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
  })
}

export const createSessionRecording = (builder) => {
  return builder.mutation({
    query: (payload) => ({
      url: `recordings.create`,
      method: 'POST',
      body: payload,
    }),
  })
}

export default {
  getUserRecordings,
  createSessionRecording,
}

// import { apiHubSlice } from '../apiHubSlice'

// export const assignmentsApiSlice = apiHubSlice.injectEndpoints({
//   endpoints: (builder) => ({
//     getUserRecordings: builder.query({
//       query: (userId) => ({
//         url: `users.listRecordings`,
//         method: 'POST',
//         body: {
//           userId,
//         },
//       }),
//     }),
//   }),
// })

import { configureStore } from '@reduxjs/toolkit'
import { apiHubSlice } from './apiHubSlice'
import { uiSlice } from './uiSlice'
import { assigmentCreationSlice } from './assigmentCreationSlice'
import { pollCreationSlice } from './pollCreationSlice'
import { authSlice } from './authSlice'
import { discussionSlice } from './discussionSlice'
export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    assigmentCreation: assigmentCreationSlice.reducer,
    pollCreation: pollCreationSlice.reducer,
    discussion: discussionSlice.reducer,
    [apiHubSlice.reducerPath]: apiHubSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(apiHubSlice.middleware),
})

export const getSurvey = (builder) => {
  return builder.query({
    query: ({ id }) => ({
      url: '/surveys.info',
      method: 'POST',
      body: { id },
    }),
  })
}

export const addSurveyResponse = (builder) => {
  return builder.mutation({
    query: ({ surveyId, response }) => ({
      url: '/surveyResponses.create',
      method: 'POST',
      body: { surveyId, response },
    }),
  })
}

export default {
  getSurvey,
  addSurveyResponse
}

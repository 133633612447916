import { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { dark, light } from '../../styles/theme'
import queryString from 'query-string'
import GlobalStyles from '../../styles/globals'

export default function Theme({ children }) {
  const { theme } = queryString.parse(window.location.search)
  const resolvedTheme = theme === 'dark' ? dark : light

  useEffect(() => {
    window.dispatchEvent(new Event('theme-changed'))
  }, [theme])

  return (
    <ThemeProvider theme={resolvedTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

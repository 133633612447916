export const getTypeformInfo = (builder) => {
  return builder.query({
    query: ({ formId }) => ({
      url: `/typeforms.info`,
      method: 'POST',
      body: { formId },
    }),
  })
}

export const getTypeformInfoResponse = (builder) => {
  return builder.mutation({
    query: ({ formId, responseId }) => ({
      url: `/typeforms.infoResponse`,
      method: 'POST',
      body: { formId, responseId },
    }),
    transformResponse: (response) => response.data,
  })
}

export const downloadTypeformFile = (builder) => {
  return builder.mutation({
    query: (fileUrl) => ({
      url: `/typeforms.downloadFile`,
      method: 'POST',
      body: { fileUrl },
    }),
    responseHandler: async (response) => await response.blob(),
  })
}

export default {
  getTypeformInfo,
  getTypeformInfoResponse,
  downloadTypeformFile,
}

export const addSessionRecordingView = (builder) => {
  return builder.mutation({
    query: (payload) => ({
      url: `sessionRecordingViews.create`,
      method: 'POST',
      body: payload,
    }),
  })
}

export default {
  addSessionRecordingView,
}

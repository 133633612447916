export const getEnrollmentGroupById = (builder) => {
  return builder.query({
    query: (id) => ({
      url: '/enrollmentGroups.info',
      method: 'POST',
      body: {
        id,
      },
    }),
    transformResponse: (response) => response.data,
    providesTags: ['EnrollmentGroup'],
  })
}

export const getEnrollmentGroupByDocumentId = (builder) => {
  return builder.query({
    query: (documentId) => ({
      url: '/enrollmentGroups.infoByDocumentId',
      method: 'POST',
      body: {
        documentId,
      },
    }),
    transformResponse: (response) => response.data,
    providesTags: ['EnrollmentGroup'],
  })
}

export const getEnrollmentGroups = (builder) => {
  return builder.query({
    query: (params) => ({
      url: '/enrollmentGroups.list',
      method: 'POST',
      body: params,
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ id }) => ({ type: 'EnrollmentGroup', id })),
            { type: 'EnrollmentGroup', id: 'LIST' },
          ]
        : [{ type: 'EnrollmentGroup', id: 'LIST' }],
    transformResponse: (response) => response.data,
  })
}

export default {
  getEnrollmentGroups,
  getEnrollmentGroupById,
  getEnrollmentGroupByDocumentId,
}

export const getDocumentById = (builder) => {
  return builder.query({
    query: (documentId) => ({
      url: `/documents.info`,
      method: 'POST',
      body: {
        id: documentId,
      },
    }),
  })
}

export default {
  getDocumentById,
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  ai,
  recordings,
  users,
  assignments,
  forums,
  submissions,
  documents,
  typeforms,
  elements,
  enrollmentGroups,
  instructors,
  classSessions,
  classSessionEnrollmentGroups,
  classSessionAttendance,
  surveys,
  sessionRecordingViews,
} from './slices'

export const apiHubSlice = createApi({
  reducerPath: 'apiHub',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_HUB_API + '/api',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.headerAuth
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: [
    'User',
    'Post',
    'Assignment',
    'Submission',
    'Document',
    'Typeform',
    'Recording',
    'Element',
    'EnrollmentGroup',
    'Instructor',
    'ClassSession',
    'ClassSessionEnrollmentGroup',
    'ClassSessionAttendance',
    'SessionRecordingView',
  ],
  endpoints: (builder) => ({
    // Users
    userInfo: users.userInfo(builder), // Mutation
    getUserInfo: users.getUserInfo(builder), // Query
    getUsersById: users.getUsersById(builder), // Query
    getUserByStudentId: users.getUserByStudentId(builder), // query
    // Assignments
    getAssignmentById: assignments.getAssignmentById(builder), // Query
    getAssignmentTypes: assignments.getAssignmentTypes(builder), // Query
    createAssignment: assignments.createAssignment(builder), // Mutation
    updateAssignment: assignments.updateAssignment(builder), // Mutation
    toggleAssignmentOpen: assignments.toggleOpen(builder), // Mutation
    // Submissions
    getSubmissions: submissions.getSubmissions(builder), // Query
    getSubmissionById: submissions.getSubmissionById(builder), // Query
    createSubmission: submissions.createSubmission(builder), // Mutation
    updateSubmission: submissions.updateSubmission(builder), // Mutation
    deleteSubmission: submissions.deleteSubmission(builder), // Mutation
    getSubmissionByAssignmentIdAndUserId:
      submissions.getSubmissionByAssignmentIdAndUserId(builder), // Query
    // Documents
    getDocumentById: documents.getDocumentById(builder), // Query
    // Forum Posts
    getForumPostsByAssignmentId: forums.getForumPostsByAssignmentId(builder), // Query
    createForumPost: forums.createForumPost(builder), // Mutation
    updateForumPost: forums.updateForumPost(builder), // Mutation
    deleteForumPost: forums.deleteForumPost(builder), // Mutation
    toggleForumPostLike: forums.toggleForumPostLike(builder), // Mutation
    getForumPostComments: forums.getForumPostComments(builder), // Query
    createForumPostComment: forums.createForumPostComment(builder), // Mutation
    toggleForumPostCommentLike: forums.toggleForumPostCommentLike(builder), // Mutation
    // Recordings
    getUserRecordings: recordings.getUserRecordings(builder), // Query
    createSessionRecording: recordings.createSessionRecording(builder), // Mutation
    // Typeforms
    getTypeformInfo: typeforms.getTypeformInfo(builder), // Query
    getTypeformInfoResponse: typeforms.getTypeformInfoResponse(builder), // Mutation
    downloadTypeformFile: typeforms.downloadTypeformFile(builder), // Mutation
    // Elements
    getElementById: elements.getElementById(builder), // Query
    createElement: elements.createElement(builder), // Mutation
    updateElement: elements.updateElement(builder), // Mutation
    toggleElementOpen: elements.toggleOpen(builder), // Mutation
    getElementBoard: elements.getElementBoard(builder), // Query
    addPollVote: elements.addPollVote(builder), // Mutation
    addRaceSubmission: elements.addRaceSubmission(builder), // Mutation
    // Enrollment Groups
    getEnrollmentGroups: enrollmentGroups.getEnrollmentGroups(builder), // Query
    getEnrollmentGroupById: enrollmentGroups.getEnrollmentGroupById(builder), // Query
    getEnrollmentGroupByDocumentId:
      enrollmentGroups.getEnrollmentGroupByDocumentId(builder), // Query
    // Instructors
    getInstructors: instructors.getInstructors(builder), // Query
    // Discussions
    addDiscussionComment: elements.addDiscussionComment(builder), // Mutation
    toggleDiscussionUpvote: elements.toggleDiscussionUpvote(builder), // Mutation
    paginateDiscussionComments: elements.paginateDiscussionComments(builder), // Query
    // Class Sessions
    getClassSessions: classSessions.getClassSessions(builder), // Query
    createClassSession: classSessions.createClassSession(builder), // Mutation
    getClassSession: classSessions.getClassSession(builder), // Query
    // Class Session Enrollment Groups
    addClassSessionEnrollmentGroup:
      classSessionEnrollmentGroups.addClassSessionEnrollmentGroup(builder),
    // Class Session Attendance
    addClassSessionAttendance:
      classSessionAttendance.addClassSessionAttendance(builder), // Mutation
    // AI
    generateAIQuiz: ai.generateQuiz(builder), // Mutation
    // Surveys
    getSurvey: surveys.getSurvey(builder), // Query
    addSurveyResponse: surveys.addSurveyResponse(builder), // Mutation
    // Session Recording View
    addSessionRecordingView:
      sessionRecordingViews.addSessionRecordingView(builder),
  }),
})

export const {
  // Recordings
  useGetUserRecordingsQuery,
  useCreateSessionRecordingMutation,
  // Users
  useUserInfoMutation,
  useGetUserInfoQuery,
  useGetUsersByIdMutation,
  useGetUserByStudentIdQuery,
  // Assignments
  useGetAssignmentByIdQuery,
  useGetAssignmentTypesQuery,
  useCreateAssignmentMutation,
  useUpdateAssignmentMutation,
  useToggleAssignmentOpenMutation,
  // Submissions
  useGetSubmissionsQuery,
  useGetSubmissionByIdQuery,
  useCreateSubmissionMutation,
  useUpdateSubmissionMutation,
  useDeleteSubmissionMutation,
  useGetSubmissionByAssignmentIdAndUserIdQuery,
  // Documents
  useGetDocumentByIdQuery,
  // Forum Posts
  useGetForumPostsByAssignmentIdQuery,
  useCreateForumPostMutation,
  useUpdateForumPostMutation,
  useDeleteForumPostMutation,
  useToggleForumPostLikeMutation,
  // Forum Post Comments
  useGetForumPostCommentsQuery,
  useCreateForumPostCommentMutation,
  useToggleForumPostCommentLikeMutation,
  // Typeforms
  useGetTypeformInfoQuery,
  useGetTypeformInfoResponseMutation,
  useDownloadTypeformFileMutation,
  // Elements
  useCreateElementMutation,
  useGetElementByIdQuery,
  useUpdateElementMutation,
  useToggleElementOpenMutation,
  useGetElementBoardQuery,
  useAddPollVoteMutation,
  useAddRaceSubmissionMutation,
  // Enrollment Groups
  useGetEnrollmentGroupsQuery,
  useGetEnrollmentGroupByIdQuery,
  useGetEnrollmentGroupByDocumentIdQuery,
  // Instructors
  useGetInstructorsQuery,
  // Discussions
  useAddDiscussionCommentMutation,
  useToggleDiscussionUpvoteMutation,
  usePaginateDiscussionCommentsQuery,
  // Class Sessions
  useGetClassSessionsQuery,
  useCreateClassSessionMutation,
  useGetClassSessionQuery,
  // Class Session Enrollment Groups
  useAddClassSessionEnrollmentGroupMutation,
  // Class Session Attendance
  useAddClassSessionAttendanceMutation,
  // AI
  useGenerateAIQuizMutation,
  // Surveys
  useGetSurveyQuery,
  useAddSurveyResponseMutation,
  // Session Recording View
  useAddSessionRecordingViewMutation,
} = apiHubSlice

import { createSlice } from '@reduxjs/toolkit'

export const pollCreationSlice = createSlice({
  name: 'pollCreation',
  initialState: {
    configuration: {
      title: '',
      options: [],
      public: false,
      layout: 'default',
      subheading: '',
      colors: {},
      isSynchronous: false,
      anonymousResponse: true,
    },
    data: {
      votes: {},
      results: {},
    },
  },
  reducers: {
    setPollConfiguration: (state, action) => {
      state.configuration = action.payload
    },
  },
})

export const selectConfiguration = (state) => state.pollCreation.configuration

export const { setPollConfiguration } = pollCreationSlice.actions

export default pollCreationSlice.reducer

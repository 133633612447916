import { useEffect, useRef } from 'react'
import queryString from 'query-string'
import ParentInformer from '../utils/ParentInformer'

export default function HeightProvider(props) {
  const { assignmentId, elementId } = queryString.parse(window.location.search)
  const observerRef = useRef(null)
  const targetRef = useRef(null)

  const onMutation = () => {
    const el = targetRef.current
    let clientHeight = el.clientHeight

    if (clientHeight < 50) {
      clientHeight = 50
    } else if (clientHeight > 1500) {
      clientHeight = 1500
    }

    ParentInformer.sendHeight({ id: assignmentId || elementId }, `${clientHeight}`)
  }

  useEffect(() => {
    observerRef.current = new MutationObserver(onMutation)
    onMutation()

    if (targetRef.current) {
      observerRef.current.observe(targetRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      })
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [])

  return (
    <div
      id="height-provider"
      style={{ display: 'flex', flexDirection: 'column' }}
      ref={targetRef}
    >
      {props.children}
    </div>
  )
}

import { createSlice } from '@reduxjs/toolkit'

export const discussionSlice = createSlice({
  name: 'discussion',
  initialState: {
    userOpenComments: {},
  },
  reducers: {
    openComment: (state, action) => {
      const previousComments = {}

      state.userOpenComments = {
        ...previousComments,
        ...action.payload,
      }
    },
    closeComment: (state, action) => {
      state.userOpenComments = {
        ...state.userOpenComments,
        [action.payload]: null,
      }
    },
  },
})

export const selectOpenComments = (state) => state.discussion.userOpenComments
export const { openComment, closeComment } = discussionSlice.actions

export default discussionSlice.reducer

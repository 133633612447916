// GENERATE AI QUIZ
export const generateQuiz = (builder) => {
  return builder.mutation({
    query: ({ documentId, questionCount }) => ({
      url: '/ai.generateQuiz',
      method: 'POST',
      body: { documentId, questionCount },
    }),
  })
}

export default {
  generateQuiz
}

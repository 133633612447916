import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  html,
  body,
  div,
  section,
  iframe {
      /* overflow: hidden; */
      margin: 0;
      padding: 0;
      font-family: Roboto, monospace;
      color: #293845;
  }

  html,
  body {
      background-color: ${(props) => props.theme.background};
      color: ${(props) => props.theme.text};
  }

  h1, h2, h3, h4, h5, h6 {
      color: ${(props) => props.theme.text};
  }

  span,
  p {
      color: ${(props) => props.theme.text};
  }

  textarea {
      resize: none;
  }

  .rdw-editor-main {
      background-color: #fff;
      padding: 10px;
      min-height: 200px;
      margin: 2px;
      border: 1px solid #f5f5f5;
  }

  .ui.card>.image>img,
  .ui.cards>.card>.image>img {
      height: 80px;
      object-fit: cover;
      margin: 0;
  }

  .offline {
      color: gray;
      opacity: 0.5;
      background-color: rgba(0, 0, 0, 0.7);
      cursor: not-allowed;
  }

  .offline-text {
      color: gray !important;
  }

  .ui.card:first-child {
      margin: 1em 0;
  }

  .ui.card:last-child {
      margin-bottom: 1em;
  }

  .hqNDYz .ui.card {
      max-height: 221px;
  }

  .ui.segment {
    background-color: var(--background-color);
    color: var(--text-color);
  }
`

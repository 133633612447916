export const addClassSessionAttendance = (builder) => {
  return builder.mutation({
    query: (body) => ({
      url: '/classSessionAttendances.create',
      method: 'POST',
      body,
    }),
    transformResponse: (response) => response.data,
  })
}

export default {
  addClassSessionAttendance,
}

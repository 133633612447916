export const getClassSessions = (builder) => {
  return builder.query({
    query: (params) => ({
      url: '/classSessions.list',
      method: 'POST',
      body: params,
    }),
    providesTags: (result) =>
      result && result.data
        ? [
            ...result.data.map(({ id }) => ({ type: 'ClassSession', id })),
            { type: 'ClassSession', id: 'LIST' },
          ]
        : [{ type: 'ClassSession', id: 'LIST' }],
    transformResponse: (response) => response.data,
  })
}

export const createClassSession = (builder) => {
  return builder.mutation({
    query: (params) => ({
      url: '/classSessions.create',
      method: 'POST',
      body: params,
    }),
    invalidatesTags: [{ type: 'ClassSession', id: 'LIST' }],
  })
}

export const getClassSession = (builder) => {
  return builder.query({
    query: ({ slug, includes }) => ({
      url: '/classSessions.info',
      method: 'POST',
      body: { slug, includes },
    }),
    providesTags: (result, error, slug) => [{ type: 'ClassSession', slug }],
    transformResponse: (response) => response.data,
  })
}

export default {
  getClassSessions,
  createClassSession,
  getClassSession,
}

export const addClassSessionEnrollmentGroup = (builder) => {
  return builder.mutation({
    query: (body) => ({
      url: '/classSessionEnrollmentGroups.create',
      method: 'POST',
      body,
    }),
  })
}

export default {
  addClassSessionEnrollmentGroup,
}

import { useState, useEffect } from 'react'
import { createContext } from 'react'
import io from 'socket.io-client'
import queryString from 'query-string'

export const useSocket = () => {
  const { elementId, assignmentId } = queryString.parse(window.location.search);

  const [socket] = useState(() => {
    return io(process.env.REACT_APP_HUB_API, {
      path: '/realtime',
      transports: ['websocket'],
      reconnectionDelay: 1000,
      reconnectionDelayMax: 30000,
      withCredentials: true,
      autoConnect: true,
    })
  })

  useEffect(() => {
    // Join rooms
    if (elementId) {
      socket.emit('joinElement', { elementId })
    }

    if (assignmentId) {
      socket.emit('joinAssignment', { assignmentId })
    }

    return () => {
      socket.emit('leaveElement', { elementId })
      socket.emit('leaveAssignment', { assignmentId })
      socket.disconnect()
    }
  }, [])

  return { socket }
}

export const SocketContext = createContext()

export const SocketProvider = ({ children, token }) => {
  const { socket } = useSocket(token)

  return (
    <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
  )
}

export default function randomImage() {
  const base1 = 'https://hybridge-cdn.s3.amazonaws.com/covers'
  const images = [
    `${base1}/alchemy.gif`,
    `${base1}/asteroids.jpg`,
    `${base1}/brijan.gif`,
    `${base1}/cuadros.png`,
    `${base1}/kale-salad.jpg`,
    `${base1}/science.png`,
    `${base1}/subway-lines.png`,
    `${base1}/wild-sea.png`,
  ]
  return images[Math.floor(Math.random() * images.length)] || images[0]
}

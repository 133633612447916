import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: {
      id: '',
      name: '',
      givenName: '',
      familyName1: '',
      familyName2: '',
      isStudent: false,
      isInstructor: false,
      isSchoolAdmin: false,
      isCreator: false,
      isAdmin: false,
      avatarUrl: '',
    },
    headerAuth: null,
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    setHeaderAuth: (state, action) => {
      state.headerAuth = action.payload
    },
  },
})

export const selectCurrentUser = (state) => state.auth.currentUser
export const getHeaderAuth = (state) => state.auth.headerAuth

export const { setCurrentUser } = authSlice.actions
export const { setHeaderAuth } = authSlice.actions

export default authSlice.reducer

import { createSlice } from '@reduxjs/toolkit'
import randomImage from '../utils/randomImage'
export const assigmentCreationSlice = createSlice({
  name: 'assigmentCreation',
  initialState: {
    assigment: {
      id: '',
      title: '',
      description: '',
      aiContext: null,
      assignmentType: null,
      code: null,
      configuration: {
        instructions: '',
        isChallenge: false,
        image: randomImage(),
        requiresFeedback: false,
        defaultOpen: true,
        autoFeedback: false,
        isSynchronous: false,
        isOpen: true,
        quiz: [],
      },
    },
    ready: false,
    autoCreatingAssignment: false,
    errorMessage: null,
  },
  reducers: {
    setAssigment: (state, action) => {
      state.assigment = action.payload
    },
    setReady: (state, action) => {
      state.ready = action.payload
    },
    setAutoCreatingAssignment: (state, action) => {
      state.autoCreatingAssignment = action.payload
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload
    },
  },
})

export const selectAssigment = (state) => state.assigmentCreation.assigment
export const selectAssigmentByType = (type) => (state) => {
  let assigment = { ...state.assigmentCreation.assigment }

  if (type == 'exam') {
    let configCopy = { ...assigment.configuration, image: '' }
    assigment.configuration = configCopy
  }

  return assigment
}
export const selectReady = (state) => state.assigmentCreation.ready
export const selectAutoCreatingAssignment = (state) =>
  state.assigmentCreation.autoCreatingAssignment
export const selectErrorMessage = (state) =>
  state.assigmentCreation.errorMessage
export const {
  setAssigment,
  setReady,
  setAutoCreatingAssignment,
  setErrorMessage,
} = assigmentCreationSlice.actions

export default assigmentCreationSlice.reducer

export default class ParentInformer {
  static sendHeight(assigment, height) {
    const m = {
      type: 'height',
      assigmentId: assigment.id,
      height: height.toString(),
    }
    this.sendToParent(m)
  }
  static sendElementHeight(element_id, height) {
    const m = {
      type: 'height',
      elementId: element_id,
      height: height.toString(),
    }
    this.sendToParent(m)
  }
  static sendSubmission(assignment) {
    const m = {
      type: 'submission',
      assignmentTitle: assignment.title,
      assignmentId: assignment.id,
      assignmentType:
        assignment.assignmentType?.name,
    }
    this.sendToParent(m)
  }
  static sendSubmissionUpdate(submission) {
    const m = {
      type: 'submissionUpdate',
      submission,
    }
    this.sendToParent(m)
  }
  static sendEngagement({ eventName, elementType, element }) {
    const m = {
      type: 'trackEngagement',
      eventName,
      elementType,
      object: element,
    }
    this.sendToParent(m)
  }
  static sendAssigmentTitle(assignment) {
    const m = {
      type: 'title',
      assignmentId: assignment.id,
      title: assignment.title,
    }
    this.sendToParent(m)
  }
  static sendToParent(message) {
    window.parent.postMessage(JSON.stringify(message), '*')
  }
  static sendMeetUrl(url) {
    this.sendToParent({ newUrl: url })
  }
  static sendPathUrl(path) {
    this.sendToParent({ pathUrl: path })
  }
  static sendBlinkInstruction(element_id) {
    const m = {
      type: 'blink',
      elementId: element_id,
    }
    this.sendToParent(m)
  }
  static sendReloadInstruction() {
    const m = {
      type: 'reload',
    }
    this.sendToParent(m)
  }
  static sendSurveySubmission(surveyId) {
    const m = {
      type: 'surveySubmission',
      surveyId,
    }
    this.sendToParent(m)
  }
}

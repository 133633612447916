import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
import ParentInformer from 'app/utils/ParentInformer'

export const ErrorMessageWithReload = ({
  title,
  description,
  action = 'Recargar',
  onClick,
}) => {
  const onReloadButtonClicked = () => {
    ParentInformer.sendReloadInstruction()
  }

  return (
    <ErrorContainer>
      <ErrorTitleContainer>
        <ErrorTitle>{title}</ErrorTitle>
      </ErrorTitleContainer>
      <p>{description}</p>
      <br />
      <Button onClick={onClick ?? onReloadButtonClicked}>{action}</Button>
    </ErrorContainer>
  )
}

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
`
const ErrorTitle = styled.h1`
  margin: 0;
  padding: 0;
`
const ErrorTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

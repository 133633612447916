export const getElementById = (builder) => {
  return builder.query({
    query: (id) => ({
      url: `/elements.info`,
      method: 'POST',
      body: {
        id,
      },
    }),
    transformResponse: (response) => response.data,
    providesTags: ['Element'],
  })
}

export const updateElement = (builder) => {
  return builder.mutation({
    query: (element) => ({
      url: `/elements.update`,
      method: 'POST',
      body: element,
    }),
    transformResponse: (response) => response.data,
    invalidatesTags: (result, error, element) => [
      { type: 'Element', id: element.id },
    ],
  })
}

export const toggleOpen = (builder) => {
  return builder.mutation({
    query: (element) => ({
      url: `/elements.toggleOpen`,
      method: 'POST',
      body: element,
    }),
    transformResponse: (response) => response.data,
    invalidatesTags: (result, error, element) => [
      { type: 'Element', id: element.id },
    ],
  })
}

export const createElement = (builder) => {
  return builder.mutation({
    query: (element) => ({
      url: `/elements.create`,
      method: 'POST',
      body: element,
    }),
    transformResponse: (response) => response.data,
    invalidatesTags: [{ type: 'Element', id: 'LIST' }],
  })
}

export const getElementBoard = (builder) => {
  return builder.query({
    query: (boardId) => ({
      url: `/elements.board`,
      method: 'POST',
      body: {
        boardId,
      },
    }),
    transformResponse: (response) => response.data,
    providesTags: ['Element'],
  })
}

export const addPollVote = (builder) => {
  return builder.mutation({
    query: (dto) => ({
      url: `/elements.addPollVote`,
      method: 'POST',
      body: dto,
    }),
  })
}

export const addDiscussionComment = (builder) => {
  return builder.mutation({
    query: (dto) => ({
      url: `/elements.addDiscussionComment`,
      method: 'POST',
      body: dto,
    }),
  })
}

export const toggleDiscussionUpvote = (builder) => {
  return builder.mutation({
    query: (dto) => ({
      url: `/elements.toggleDiscussionUpvote`,
      method: 'POST',
      body: dto,
    }),
  })
}

export const paginateDiscussionComments = (builder) => {
  return builder.query({
    query: (dto) => ({
      url: `/elements.paginateDiscussionComments`,
      method: 'POST',
      body: dto,
    }),
  })
}

export const addRaceSubmission = (builder) => {
  return builder.mutation({
    query: (dto) => ({
      url: `/elements.addRaceSubmission`,
      method: 'POST',
      body: dto,
    }),
  })
}

export default {
  getElementById,
  createElement,
  updateElement,
  getElementBoard,
  addPollVote,
  addDiscussionComment,
  toggleDiscussionUpvote,
  paginateDiscussionComments,
  addRaceSubmission,
  toggleOpen
}

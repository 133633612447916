export const userInfo = (builder) => {
  return builder.mutation({
    query: (id) => ({
      url: `users.info`,
      method: 'POST',
      body: { id },
    }),
    transformResponse: (response) => response.data,
    providesTags: ['User'],
  })
}

export const getUserInfo = (builder) => {
  return builder.query({
    query: () => ({
      url: `users.info`,
      method: 'POST',
    }),
  })
}

export const getUsersById = (builder) => {
  return builder.mutation({
    query: (userIds) => ({
      url: `users.findUsersById`,
      method: 'POST',
      body: { userIds },
    }),
    transformResponse: (response) => response.data,
  })
}

export const getUserByStudentId = (builder) => {
  return builder.query({
    query: ({ studentId }) => ({
      url: `/users.findUserByStudentId`,
      method: 'POST',
      body: { studentId },
    }),
    providesTags: (result, error, studentId) => [{ type: 'User', studentId }],
    transformResponse: (response) => response.data,
  })
}

export default {
  userInfo,
  getUserInfo,
  getUsersById,
  getUserByStudentId,
}
